<template>
    <div style="min-height: 400px;">
        <v-card class="mx-auto ma-15 pa-10 text-center" max-width="1200">
            <div class="text-h3"> NEED A LICENCE FOR ACCESS</div>
        </v-card>
         <!-- <p class="text-center">or if you have a code </p>
        <v-card width="700" class="mx-auto pa-5">
            <v-row>
                <v-col cols="4" class="my-auto text-center">
                    Enter Code: 
                </v-col>
                <v-col cols="4" class="my-auto">
                    <v-text-field single-line filled outlined v-model="code" @keyup="uppercase"></v-text-field>
                </v-col>
                <v-col class="my-2">
                    <v-btn depressed color="primary" @click="SearchCode">
                        Enter
                    </v-btn>
                </v-col>
            </v-row>
        </v-card> -->
    </div>
</template>
<script>
import { db, functions } from '../../plugins/firebase'
export default {
    data() {
        return {
            code: ''
        }
    },
    methods: {
        // async SearchCode() {
        //     let find = await db.collection('CodeAccessStudents').doc(this.code).get(); 
        //     console.log(find.exists)
        //     if (find.exists) {
                
        //     }
        // },
        // uppercase() {
        //     this.code = this.code.toUpperCase();
        // }
    },
}
</script>